.copy-to-clipboard-button {
    background-color: white;
    border: 1px solid #dee2e6;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.2s ease;
    opacity: 0.8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.copy-to-clipboard-button:hover {
    opacity: 1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
} 
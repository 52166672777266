.centered-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh; /* Full viewport height */
	text-align: center;
}

.centered-container button {
	margin-top: 10px; /* Add margin to separate buttons */
}

/* .btn-container{
    position: fixed;
    right: 20px;
    top : 20px;
} */

.logout-btn,
.login-btn {
	position: relative;
	width: 150px;
	color: #fff;
	/* margin: 0 15px; */
	padding: 12px;
	border: none;
	outline: none;
}

.login-btn {
	background-color: #0b5ed7;
}

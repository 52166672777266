html,
body,
#root {
	height: 100%;
	margin: 0;
	display: flex;
	flex-direction: column;
}

.outer-container {
	height: 95vh;
}

.chat-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.chat-messages {
	overflow-y: auto;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
	margin-bottom: 10px;
	/* flex-grow: 1; */
}

.message {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	width: 60%;
	max-width: 60%;
}

.message.user {
	justify-content: flex-end;
	text-align: right;
	margin-left: auto;
}

.message.assistant {
	justify-content: flex-start;
	text-align: left;
	margin-right: auto;
}

.message-content {
	margin: 0 10px;
	padding: 10px;
	border-radius: 4px;
	display: inline-block;
	/* white-space: pre-wrap; */
	text-align: left !important;
	overflow-x: auto;
}

.message.user .message-content {
	padding: 10px 20px 10px 20px;
	background-color: #afe085;
}

.message.assistant .message-content {
	padding: 10px 20px 8px 20px;
	background-color: #c7d8ff;
}

.message-input {
	flex-grow: 1;
	min-height: 10px;
	max-height: 300px;
	resize: none;
	overflow-y: auto;
}

.file-input {
	align-self: center;
}

.icon {
	font-size: 1.5em;
}

.chat-input {
	display: flex;
	border-top: 1px solid #ddd;
	padding: 10px;
	background-color: #e1e1e1;
	box-sizing: border-box;
	justify-content: space-between;
	border-radius: 10px;
}

.chat-input textarea {
	display: flex;
	align-items: flex-end;
	flex-grow: 1;
	margin-right: 10px;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.chat-input button {
	padding: 5px 10px;
}

.submit-btn {
	background-color: #0b5ed7;
	color: #f8f9fa;
	font-size: 16px !important;
	border: none;
	width: 150px;
	border-radius: 10px;
	height: 50px;
	align-self: center;
}

.prompt {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 0;
}

.drag-btn {
	width: 10px;
}

.push-changes {
	padding: 8px 10px;
}

h1 {
	padding-left: 0;
}

.back {
	background-color: #b2b0b0;
	color: #fff;
	padding: 10px;
	border-radius: 50%;
	font-size: 2rem;
	margin-bottom: 20px;
}

.document-modal {
	padding: 20px;
	min-width: 60% !important;
}

.spinner-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1050; /* Higher than the Modal's z-index */
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(720deg);
	}
}

.rotate-animation {
	animation: rotate 1s ease-in-out;
}

.dropdown-list {
	max-height: 400px;
	overflow-y: scroll;
}

.core-data-input {
	font-size: 14px;
	background-color: #f8f9fa;
	overflow: hidden;
}

.feedback-input {
	font-size: 16px;
	background-color: #e9ecef;
	border: 2px solid #ced4da;
}

.work-unit-card {
	background: linear-gradient(145deg, #f6f8fa, #e6e9ec);
	border-radius: 20px;
	box-shadow: 10px 10px 20px #d1d9e6, -10px -10px 20px #ffffff;
	transition: all 0.3s ease;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.work-unit-card:hover {
	transform: translateY(-5px) scale(1.02);
	box-shadow: 15px 15px 30px #b8c2cf, -15px -15px 30px #ffffff;
}

.work-unit-thumbnail {
	height: 200px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.work-unit-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.work-unit-card:hover .work-unit-thumbnail {
	transform: scale(1.1);
	filter: brightness(1.1) contrast(1.1);
}

.work-unit-card::after {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 200%;
	height: 200%;
	transform: rotate(-45deg);
	transition: all 0.3s ease;
	opacity: 0;
}

.work-unit-card:hover::after {
	opacity: 1;
}

.card-body {
	flex-grow: 1;
	overflow-y: auto;
}

.text-muted {
	color: #6c757d;
	background-color: white;
	-webkit-text-size-adjust: 100%;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji';
	font-size: 16px;
	line-height: 1.5;
	word-wrap: break-word;
}

.em {
	font-style: italic;
}

.text-primary {
	color: #007bff;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.stage-pipeline {
	display: flex;
	justify-content: space-between;
}

.stage {
	flex: 1;
	text-align: center;
	padding: 10px 10px 0px 10px;
	border-top: 3px solid rgb(108, 117, 125);
	position: relative;
}

.stage.completed {
	border-top-color: rgb(13, 202, 240);
}

.stage.current {
	border-top-color: rgb(13, 110, 253);
	font-weight: bold;
}

.stage:after {
	content: '';
	position: absolute;
	top: -6px;
	right: -6px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: rgb(108, 117, 125);
	z-index: 2;
}

.stage.completed:after {
	background: rgb(13, 202, 240);
}

.stage.current:after {
	background: rgb(13, 110, 253);
}

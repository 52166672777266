.worker-cot-tools {
  margin: 1rem 0 1.5rem;
}

.worker-cot-tools__header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.worker-cot-tools__interaction {
  margin-bottom: 1rem;
}

.worker-cot-tools__tool-header {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.worker-cot-tools__markdown {
  background-color: inherit;
  color: inherit;
  font-size: 0.9rem;
}

.worker-cot-tools__expandable {
  position: relative;
}

.worker-cot-tools__content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.worker-cot-tools__fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, transparent, var(--bs-body-bg) 50%);
  padding: 20px 0 5px;
  text-align: center;
  cursor: pointer;
}

.worker-cot-tools__show-more-text {
  color: var(--bs-secondary);
  font-size: 0.875rem;
  cursor: pointer;
}

.worker-cot-tools__show-less {
  text-align: center;
  margin-top: 10px;
}

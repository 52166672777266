.btn-container {
	position: absolute;
	right: 20px;
}

.search-bar {
	border-radius: '10px';
	margin-left: 20px !important;
	font-size: 0.9rem;
	padding: 10px;
}

.add-channel-btn {
	padding: 13px 40px 10px !important;
	font-size: 0.9rem !important;
	margin-right: 30px !important;
	border-radius: 10px;
}

.channel-list {
	display: flex;
	flex-wrap: wrap;
}

.channel-list .col {
	padding: 0 10px;
}

.channel-list .col > div {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.second-brain-checkbox-container {
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	padding: 0.5rem;
}

.select-all-container {
	margin-bottom: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid #ced4da;
}

.second-brain-checkbox-list {
	max-height: 150px;
	overflow-y: auto;
}

.channel-card {
	display: inline-block;
	border-radius: 8px;
	padding: 20px;
	margin: 20px;
	width: 300px;
	height: 400px;
	text-align: center;
	cursor: pointer;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.582);
	overflow: hidden;
	background-color: #f6f2f2bb;
	color: #0c0c0cb8;
}

.profile-img {
	width: 130px;
	height: 130px;
	min-width: 130px;
	min-height: 130px;
	border-radius: 50%;
	object-fit: cover;
	margin: 10px;
    line-height: 130px;
    text-align: center;
}

.channel-card-content {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	height: 300px;
	overflow: hidden;
}

.channel-title-name {
	color: #000;
	font-size: 1.5rem;
	margin: 10px 0;
}

.channel-desc {
	font-size: 13px;
	color: #0c0c0cf3;
	margin-bottom: 5px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 8;
	-webkit-box-orient: vertical;
	height: calc(1rem * 7);
	text-overflow: ellipsis;
}

.channel-desc p {
	margin: 0;
	line-height: 1.4;
}

.card-content span {
	font-size: 12px;
	margin-bottom: 5px;
}
/* Video Card  */
.video-card {
	border: 1px solid #ddd;
	padding: 10px;
	box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
	margin-bottom: 5px;
}

.thumbnail {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.video-stats,
.video-dates {
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	font-size: 14px;
	color: #666;
}

.video-stats span,
.video-dates span {
	display: flex;
	align-items: center;
	margin-top: 5px;
}

.icon {
	font-size: 12px;
	margin-right: 8px;
}
.video-about {
	font-size: 14px;
	color: #0c0c0cf3;
	margin-bottom: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	height: 3em;
}

.image-container {
	display: flex;
	align-items: center;
	margin-top: 25px;
	height: 200px;
}

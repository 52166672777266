:root {
	--primary-color: #3498db;
	--secondary-color: #2ecc71;
	--background-color: #f0f3f5;
	--text-color: #2c3e50;
	--node-bg-color: #ffffff;
	--node-border-color: #bdc3c7;
}

.react-flow__handle {
	width: 10px;
	height: 10px;
	background-color: var(--primary-color);
	border: 2px solid var(--node-bg-color);
}

.react-flow__handle:hover {
	background-color: var(--secondary-color);
}

.worker-chain-name-input {
	width: 100%;
	padding: 10px;
	color: var(--sidebar-text-color);
	font-size: 1.2em;
}

.worker-chains-selector select {
	width: 100%;
	padding: 10px;
	border-radius: 5px;
}

.mode-button {
	border-radius: 0 !important;
	z-index: 2;
	background-color: #343a40;
}

.floating-label-input {
	position: relative;
}

.floating-label-input input {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	background: transparent;
}

.floating-label-input label {
	position: absolute;
	left: 10px;
	top: 12px;
	transition: all 0.2s ease-out;
	pointer-events: none;
	color: #999;
	background: transparent;
}

.floating-label-input input:focus + label,
.floating-label-input input:not(:placeholder-shown) + label {
	top: -8px;
	font-size: 12px;
	position: absolute;
	padding: 0px 2px;
	background: #343a40;
}

.expandable-section {
	background-color: #2c3136;
	border: 1px solid #454d55;
	border-radius: 8px;
	margin-bottom: 20px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.expandable-heading {
	background-color: #3a4147;
	color: #ffffff;
	padding: 15px 20px;
	margin: 0;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.available-nodes {
	padding: 20px;
}

.node-item {
	background-color: #343a40;
	border: 1px solid #454d55;
	border-radius: 4px;
	padding: 12px 16px;
	margin-bottom: 10px;
	cursor: move;
	color: #ffffff;
}

.node-item:hover {
	background-color: #454d55;
}

.executions-list {
	background-color: #2c3136;
	border-radius: 8px;
	padding: 16px;
	overflow-y: auto;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.executions-list h3 {
	margin-top: 0;
	margin-bottom: 16px;
	font-size: 18px;
	color: #ecf0f1;
}

.execution-item {
	background-color: #343a40;
	border: 1px solid #454d55;
	border-radius: 4px;
	padding: 12px;
	margin-bottom: 8px;
	cursor: pointer;
	transition: all 0.3s ease;
	color: #bdc3c7;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.execution-item:hover {
	color: #ffffff;
	transform: translateY(-2px);
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.execution-item:last-child {
	margin-bottom: 0;
}

.control-bar {
	position: absolute;
	top: 20px;
	right: 20px;
	display: flex;
	background: #343a40;
	padding: 5px;
	border-radius: 25px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	z-index: 10;
}

.control-button-wrapper {
	margin: 0 5px;
}

.control-button {
	background: transparent;
	border: none;
	color: #ecf0f1; /* Light gray for contrast */
	font-size: 1rem;
	cursor: pointer;
	transition: all 0.2s ease;
	padding: 8px;
	border-radius: 50%;
}

.control-button:hover {
	background: #34495e; /* Slightly lighter blue-gray on hover */
	transform: scale(1.1);
}

.control-button.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.control-button.disabled:hover {
	background: transparent;
	transform: none;
}

.version-select {
	padding: 5px 10px;
	border-radius: 4px;
	border: 1px solid #ccc;
	background-color: white;
	font-size: 14px;
	width: 200px;
}

.version-select-container {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 4;
}

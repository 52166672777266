.channel-card {
	display: inline-block;
	border-radius: 8px;
	padding: 20px;
	margin: 20px;
	width: 300px;
	height: 400px;
	text-align: center;
	cursor: pointer;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.582);
	overflow: hidden;
	background-color: #f6f2f2bb;
	color: #0c0c0cb8;
}

.profile-img {
	width: 130px;
	height: 130px;
	border-radius: 50%;
	object-fit: cover;
	margin: 10px;
}

.card-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 200px);
	overflow: hidden;
}

.channel-name {
	font-size: 2rem;
	margin: 10px 0;
}

.channel-about {
	font-size: 14px;
	color: #0c0c0cf3;
	margin-bottom: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 12; /* controls the number of lines */
	-webkit-box-orient: vertical;
	height: 10em;
}

.card-content span {
	font-size: 12px;
	margin-bottom: 5px;
}

/* Video Card  */
.video-card {
	border: 1px solid #ddd;
	padding: 10px;
	box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
	margin-bottom: 5px;
}

.thumbnail {
	width: 85%;
	height: 60%;
	object-fit: cover;
}

.video-stats,
.video-dates {
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	font-size: 14px;
	color: #666;
}

.video-stats span,
.video-dates span {
	display: flex;
	align-items: center;
	margin-top: 5px;
}

.icon {
	font-size: 12px;
	margin-right: 8px;
}
.video-about {
	font-size: 14px;
	color: #0c0c0cf3;
	margin-bottom: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	height: 3em;
}

.image-container {
	display: flex;
	align-items: center;
	margin-top: 15px;
	height: 250px;
}

.button-row {
	width: 100%;
	margin-top: 15px;
}

.button-row button {
	width: 100%;
	padding: auto;
}

.video-thumbnail {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.video-title,
.channel-title {
	width: 350px;
	margin-top: 15px;
	font-size: 1.2rem;
	font-weight: bold;
}

.outlier-score {
	font-size: 1rem;
	font-weight: bolder;
}

.outlier-score span {
	background-color: rgb(66, 167, 43);
	padding: 5px 10px;
	border-radius: 5px;
	color: #eae7e7;
}

.current-analysis-heading {
	margin-top: 20px;
	width: 90%;
}

.channel-report-heading {
	width: 350px;
	margin-top: 25px;
	font-size: 1.8rem;
	font-weight: bold;
	color: #3b3a3a;
}

.channel-description {
	margin-top: 10px;
}

.views-card,
.likes-card,
.subs-card {
	display: inline-block;
	min-width: 170px;
	max-width: 240px;
	/* margin: 0 10px; */
}

.card-title {
	font-size: 1rem;
}

.card-value {
	font-size: 1.7rem;
	margin-bottom: 0;
}

.channel-thumbnail {
	width: 100px;
	height: 100px;
	min-width: 100px;
	min-height: 100px;
	display: inline-block;
    line-height: 50px;
    text-align: center;
}

.channel-details-card {
	width: 400px;
	margin: 0px;
}

.channel-details-card p {
	margin: 0;
}
.channel-handle,
.channel-videos {
	color: #675e5e;
}

.arrow-icon {
	transform: rotate(deg45);
	margin-left: 10px;
}

.report-title {
	font-size: 1rem;
	margin-bottom: 20px;
	cursor: pointer;
}

.report-title:hover {
	color: #6a6767a1;
}

.video-btn {
	font-size: 0.75rem;
	position: absolute;
	bottom: 0;
	margin: 5px 0 15px;
}

.videocard-heading {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	height: 100px;
	overflow: hidden;
}

.videocard-about {
	font-size: 1.2rem;
	color: #0c0c0cf3;
	margin-bottom: 5px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	height: calc(1.2em * 3);
	text-overflow: ellipsis;
}

.graph {
	margin-top: 20px;
	width: 100%;
}

.analytics-table {
	margin-top: 50px;
}

.reports-body {
	max-height: 350px;
	overflow-y: scroll;
	color: blue;
	direction: ltr;
	padding-right: 12px;
}

.report-btn {
	font-size: 0.75rem;
	margin-right: 5px;
}

.reports-table::-webkit-scrollbar {
	width: 12px; /* Width of the scrollbar */
}

.reports-table::-webkit-scrollbar-track {
	background: #999494;
	border-radius: 12px;
}

.reports-table::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 12px;
}

.reports-table::-webkit-scrollbar-thumb:hover {
	background: #555; /* Color of the thumb when hovered */
}

/* VIDEO CARD */
.videocard {
	max-width: 300px;
	min-height: 200px;
	cursor: pointer;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
	border-radius: 24px;
	padding: 10px;
}

.videocard:hover {
	transform: translateY(-5px);
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.thumbnail-img-container {
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	overflow: hidden;
	border-radius: 12px;
}

.thumbnail-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.duration {
	position: absolute;
	bottom: 8px;
	right: 8px;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	padding: 2px 4px;
	border-radius: 4px;
	font-size: 12px;
}

.videocard-info {
	padding: 12px 0;
}

.videocard-title {
	font-size: 18px;
	font-weight: 500;
	margin: 0 0 4px 0;
	line-height: 1.2;
}

.channel-name {
	font-size: 14px;
	color: #606060;
	margin-bottom: 4px;
}

.videocard-stats {
	font-size: 14px;
	color: #606060;
}

.processed-video-heading {
	font-size: 2rem;
	margin-top: 20px;
}

.videocard.selected {
	background-color: blue;
	color: white;
}
